import React from 'react';
import { Separator } from '@vkontakte/vkui';
import Component from '../../classes/component';

class MCSeparator extends Component {
    render() {
        const separatorAlias = this.getAlias('separator');

        console.log(separatorAlias && separatorAlias.style);

        return this.insideContainer(
            <Separator
                wide={separatorAlias && separatorAlias.getParam('wide', false)}
            />
        );
    }
}

export default MCSeparator;